import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  publicationListReducer,
  singlePublicationReducer,
  publicationMediaReducer,
} from "./reducers/publicationReducer";
import { cartReducer } from "./reducers/cartReducer";
import { adminReducer } from "./reducers/adminReducer";
import {
  orderReducer,
  orderDetailsReducer,
  orderListReducer,
} from "./reducers/orderReducer";
import { siteDataReducer } from "./reducers/siteDataReducer";
import { userReducer } from "./reducers/userReducer";

const reducer = combineReducers({
  siteData: siteDataReducer,
  // list
  publicationList: publicationListReducer,
  // single
  singlePublication: singlePublicationReducer,
  // meida
  publicationMedia: publicationMediaReducer,
  // cart
  cart: cartReducer,
  // login
  userLogin: adminReducer,
  // admin creates new users
  adminActions: userReducer,
  // general user place an order
  order: orderReducer,
  // admin order reducers
  // list
  orderList: orderListReducer,
  // details
  orderDetails: orderDetailsReducer,
});
const loginFromStorage = localStorage.getItem("loginInfo")
  ? JSON.parse(localStorage.getItem("loginInfo"))
  : null;

const cartItemFromStorage = localStorage.getItem("randommanCart")
  ? JSON.parse(localStorage.getItem("randommanCart"))
  : [];

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : null;

const totalsFromStorage = localStorage.getItem("totals")
  ? JSON.parse(localStorage.getItem("totals"))
  : null;

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initalState = {
  siteData: { loading: true, data: [] },
  cart: {
    cartItems: cartItemFromStorage || [],
    shippingAddress: shippingAddressFromStorage,
    totals: totalsFromStorage,
    userInfo: userInfoFromStorage,
  },
  userLogin: { loginInfo: loginFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
