import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer>
      <div>
        <div className="flex flex-row justify-around mt-5 text-center">
          <div>
            <Link to="/shipping-info" className="hover:text-pink-500">
              shipping info
            </Link>
          </div>
          <div>
            <Link to="/return-policy" className="hover:text-teal-400">
              return policy
            </Link>
          </div>
          <div>
            <Link to="/terms" className="hover:text-lime-600">
              terms & conditions
            </Link>
          </div>
        </div>
        <div>
          <div className="text-center py-3">
            Copyright &copy; Random Man Editions
          </div>
        </div>
      </div>
    </footer>
  );
}
