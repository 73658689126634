import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import MediaManager from "../../components/adminComponents/MediaManager";
import {
  createPublication,
  singlePublicationReset,
} from "../../actions/publicationActions";

export default function NewPub() {
  const navigate = useNavigate();

  //// refs for form
  const title = useRef();
  const author = useRef();
  const format = useRef();
  const tag = useRef();
  const price = useRef();
  const qty = useRef();
  const description = useRef();
  const mediaTypes = useRef();
  const sortRule = useRef();

  const [img, setImg] = useState([]);
  const [loading, setLoading] = useState(false);
  // get user/auth info
  const user = useSelector((state) => state.userLogin);

  const { loginInfo } = user;

  const dispatch = useDispatch();
  dispatch(singlePublicationReset());
  // check if user has valid creds

  useEffect(() => {
    if (!loginInfo) navigate("/"); // if there is no user info in the redux state redirect
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    // if there is user info check the token is valid
    auth();
  }, [loginInfo, navigate]);

  /// submit method to post new publication
  const createPub = async (e) => {
    e.preventDefault();
    if (img.length <= 0) {
      alert("publications must have images");
      return;
    }
    await dispatch(
      createPublication({
        title: title.current.value,
        author: author.current.value,
        format: format.current.value,
        description: description.current.value,
        media_types: mediaTypes.current.value,
        quantity: parseInt(qty.current.value),
        price: parseFloat(price.current.value),
        tag: tag.current.value,
        product_media_img: img,
        use_firstname: sortRule.current.value === "1" ? true : false,
        use_title: sortRule.current.value === "2" ? true : false,
      })
    );
    navigate("/");
  };

  return (
    <div className="p-10 pt-24">
      <h1 className="title text-3xl p-10">New Publication</h1>
      <div className="p-5 border-1 border-black">
        <form onSubmit={createPub}>
          <div className="my-2">
            <label>Title</label>
            <input
              className="pl-2 ml-3 mr-5 w-1/2 border-1 border-black"
              type="string"
              required
              placeholder="Publication Title"
              ref={title}
            />

            <label>Author / Artist</label>
            <input
              className="pl-2 ml-3 mr-5 md:w-1/4 border-1 border-black"
              type="string"
              placeholder="Author / Artist"
              ref={author}
            />
          </div>
          <div className="mt-3">
            <div className="flex flex-col lg:flex-row mt-5">
              <div className="flex flex-col w-full">
                <label className="text-center">Product Description</label>
                <textarea
                  className="p-10 ml-3 mr-5 border-1 border-black"
                  style={{ whiteSpace: "pre-wrap" }}
                  ref={description}
                  required
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-center">Format</label>
                <textarea
                  className="p-10 ml-3 mr-5 border-1 border-black"
                  style={{ whiteSpace: "pre-wrap" }}
                  ref={format}
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <div>
              <label className="mt-3 flex flex-col">
                Media Type Options
                <small>
                  {" "}
                  * use a comma seperated list to enter media options
                </small>
              </label>
              <input
                className="pl-2 ml-3 mr-5 w-1/2 border-1 border-black"
                type="string"
                ref={mediaTypes}
              />
            </div>
            <div className="pt-3">
              <label className="mt-3">Price</label>
              <input
                className="pl-2 ml-3 mr-5 sm:w-1/6 md:w-1/8 lg:w-1/12 border-1 border-black"
                type="float"
                ref={price}
                required
              />
              <br />
              <label className="mt-3">Stock</label>
              <input
                className="pl-2 ml-3 mr-5 sm:w-1/6 md:w-1/8 lg:w-1/12 border-1 border-black"
                type="number"
                ref={qty}
              />
              <label className="mt-5 ">Sorting Tag</label>
              <select
                className="p-2 ml-5 bg-white border-1 border-black sm:w-1/6 md:w-1/4 lg:w-1/6"
                aria-label="tag"
                required
                ref={tag}
              >
                <option value="still">Still</option>
                <option value="moving">Moving</option>
              </select>
              <div>
                {/* ////////// this is where im adding the dropdown for author sorting rules */}
                <label className="mt-5">Author Sort Rules</label>
                <select
                  className="p-2 ml-5 bg-white border-1 border-black sm:w-1/6 md:w-1/4 lg:w-1/6"
                  ref={sortRule}
                >
                  <option value="0">Sort by last name</option>
                  <option value="1">Sort by first name</option>
                  <option value="2">Sort by title</option>
                </select>
              </div>
            </div>
          </div>

          <button
            className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/2 md:w-1/5"
            type="submit"
          >
            Create
          </button>
        </form>
        <MediaManager
          setImg={setImg}
          loginInfo={loginInfo}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}
