import React from "react";

export default function HomeProductCard(props) {
  const firstImg = props.product.product_media.find((media) => {
    return media.img;
  }).img;

  return (
    <div className={`flex flex-col md:flex-row md:m-5 p-5 justify-between`}>
      <div className="w-full lg:w-1/2">
        <a href={`/product/${props.link}`}>
          <img src={firstImg} alt="product" />
        </a>
      </div>
      <div className="md:mt-20 p-10 lg:w-1/2">
        <h2 className="mb-5 text-2xl md:text-4xl title">
          {props.product.title} {props.product.author ? "-" : ""}{" "}
          {props.product.author}
        </h2>
        <p
          className="subTitle text-md md:text-xl"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {props.product.format}
        </p>
      </div>
    </div>
  );
}
