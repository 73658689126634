import axios from "axios";

export const getSiteData = () => async (dispatch) => {
  try {
    dispatch({ type: "SITE_DATA_REQUEST" });

    const { data } = await axios.get("/api/site-data");

    await dispatch({
      type: "SITE_DATA_SUCCESS",
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: "SITE_DATA_FAIL",
      payload: err,
    });
  }
};

export const createSiteData = (createData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "SITE_DATA_CREATE_REQUEST" });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    await axios.post(`/api/site-data/create`, createData, config);

    const { data } = await axios.get("/api/site-data");

    dispatch({
      type: "SITE_DATA_CREATE_SUCCESS",
      payload: data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: "SITE_DATA_CREATE_FAIL",
      payload: err.message,
    });
  }
};

export const editSiteData = (editData, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "SITE_DATA_EDIT_REQUEST" });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    await axios.put(`/api/site-data/edit/${id}`, editData, config);

    const { data } = await axios.get("/api/site-data");

    dispatch({
      type: "SITE_DATA_EDIT_SUCCESS",
      payload: data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: "SITE_DATA_EDIT_FAIL",
      payload: err.message,
    });
  }
};

export const createExternalLink =
  (requestData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "CREATE_EXTERNAL_LINK_REQUEST" });
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
        },
      };

      await axios.post(`/api/external-links`, requestData, config);

      const { data } = await axios.get("/api/site-data");

      dispatch({
        type: "CREATE_EXTERNAL_LINK_SUCCESS",
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: "CREATE_EXTERNAL_LINK_FAIL",
        payload: err,
      });
    }
  };

export const editExternalLink =
  (editData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: "EXTERNAL_LINK_EDIT_REQUEST" });

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
        },
      };
      const linkData = await axios.put(
        `/api/external-links/edit/${id}`,
        editData,
        config
      );
      console.log(linkData);

      const { data } = await axios.get("/api/site-data");

      dispatch({
        type: "EXTERNAL_LINK_EDIT_SUCCESS",
        payload: data,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "EXTERNAL_LINK_EDIT_FAIL",
        payload: err.message,
      });
    }
  };

export const deleteExternalLink = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EXTERNAL_LINK_DELETE_REQUEST" });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    await axios.delete(`/api/external-links/edit/${id}`, config);

    const { data } = await axios.get("/api/site-data");

    dispatch({
      type: "EXTERNAL_LINK_DELETE_SUCCESS",
      payload: data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: "EXTERNAL_LINK_DELETE_FAIL",
      payload: err.message,
    });
  }
};
