import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { listPublications } from "../../actions/publicationActions";
import PubSort from "../../components/PubSort";
export default function Publications() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPublications());
  }, [dispatch]);

  const publicationList = useSelector((state) => state.publicationList);
  const { error, loading, publications } = publicationList;

  const [showPubs, setShowPubs] = useState(publications);

  const [pubFilter, setPubFilter] = useState(null);
  const [sort, setSort] = useState("Newest");

  const creds = useSelector((state) => state.userLogin);
  const { loginInfo } = creds;

  const merge = (arr1, arr2) => {
    let newArr = [];
    let i = 0;
    let j = 0;
    while (i < arr1.length && j < arr2.length) {
      let compare1 = arr1[i].use_title
        ? arr1[i].title
        : arr1[i].use_firstname
        ? arr1[i].author
        : arr1[i].author.split(" ").pop();
      let compare2 = arr2[j].use_title
        ? arr2[j].title
        : arr2[j].use_firstname
        ? arr2[j].author
        : arr2[j].author.split(" ").pop();

      if (compare1.localeCompare(compare2) <= 0) {
        newArr.push(arr1[i]);
        i++;
      } else {
        newArr.push(arr2[j]);
        j++;
      }
    }
    i < arr1.length
      ? (newArr = [...newArr, ...arr1.slice(i)])
      : (newArr = [...newArr, ...arr2.slice(j)]);

    return newArr;
  };

  useEffect(() => {
    let copy = [...publications];
    if (sort === "Title") {
      copy = copy.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sort === "Author") {
      const authsort = (arr) => {
        if (arr.length <= 1) return arr;
        let mid = Math.floor(arr.length / 2);
        let left = authsort(arr.slice(0, mid));
        let right = authsort(arr.slice(mid));
        return merge(left, right);
      };
      copy = authsort(copy);
    } else {
      copy = copy.sort((a, b) => b.id - a.id);
    }
    if (pubFilter === "Still") {
      setShowPubs(copy.filter((item) => item.tag === "still"));
    } else if (pubFilter === "Moving") {
      setShowPubs(copy.filter((item) => item.tag === "moving"));
    } else setShowPubs(copy);
  }, [publications, sort, pubFilter]);
  //////////////////////////

  return (
    <div>
      <PubSort
        pubFilter={pubFilter}
        setPubFilter={setPubFilter}
        sort={sort}
        setSort={setSort}
      />
      <div className="relative p-5">
        {loading ? (
          <div className="flex justify-center align-center">
            <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
              L o a d i n g . . .
            </p>
          </div>
        ) : error ? (
          <h3> ERROR</h3>
        ) : (
          <div className="grid grid-col-1 relative top-36 mb-36 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {showPubs.map((product) => (
              <div key={product.id}>
                <ProductCard
                  product={product}
                  link={loginInfo ? `edit/${product.id}` : product.id}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
