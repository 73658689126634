import axios from "axios";

export const addItem = (id, mediaType) => async (dispatch, getState) => {
  const data = await axios.get(`/api/products/${id}`);

  const image = data.data.data.product_media.find((el) => !el.video).img;

  dispatch({
    type: "ADD_ITEM",
    payload: {
      product: data.data.data.id,
      name: data.data.data.title,
      format: mediaType,
      img: image,
      price: data.data.data.price,
      qty: 1,
    },
  });
  localStorage.setItem(
    "randommanCart",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const removeItem = (id) => (dispatch, getState) => {
  dispatch({
    type: "REMOVE_ITEM",
    payload: id,
  });

  localStorage.setItem(
    "randommanCart",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const addTotals =
  (subtotal, shippingcost, grandtotal) => (dispatch, getState) => {
    dispatch({
      type: "ADD_TOTALS",
      payload: { subtotal, shippingcost, grandtotal },
    });
    localStorage.setItem("totals", JSON.stringify(getState().cart.totals));
  };

export const addShippingAddress = (data) => (dispatch, getState) => {
  dispatch({
    type: "ADD_SHIPPING_ADDRESS",
    payload: data,
  });
  localStorage.setItem(
    "shippingAddress",
    JSON.stringify(getState().cart.shippingAddress)
  );
};

export const addUserInfo = (data) => (dispatch, getState) => {
  dispatch({
    type: "ADD_USER_INFO",
    payload: data,
  });
  localStorage.setItem("userInfo", JSON.stringify(getState().cart.userInfo));
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: "CLEAR_CART",
  });
  localStorage.removeItem("randommanCart");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("totals");
};
