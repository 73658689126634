import React, { useEffect } from "react";
import { getSiteData } from "../../actions/siteDataActions";
import { useSelector, useDispatch } from "react-redux";
import { FiInstagram } from "react-icons/fi";
export default function Contact() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteData());
  }, [dispatch]);

  const pageData = useSelector((state) => state.siteData);
  const { data, loading, error } = pageData;

  const contactData = data.filter((item) => item.page === "contact");

  return (
    <div className="pt-5">
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1>ERROR</h1>
      ) : data ? (
        <div className="p-10 pt-24">
          <h1 className="text-4xl">Contact</h1>

          {contactData[0]
            ? contactData[0].links.map((link, i) => {
                const url = link.url.split("").includes("@")
                  ? `mailto:${link.url}`
                  : link.url;
                return (
                  <div className="p-3" key={`link${i}`}>
                    {link.text.toLowerCase() === "instagram" ? (
                      <a
                        href={url}
                        className="text-2xl hover:text-pink-500 inline"
                      >
                        <FiInstagram className="inline" />
                        <p className="inline"> : {url.split("/")[3]}</p>
                      </a>
                    ) : (
                      <a href={url} className="text-2xl hover:text-pink-500">
                        {link.text}: {link.url}
                      </a>
                    )}
                  </div>
                );
              })
            : ""}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
