import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singlePublication } from "../../actions/publicationActions";
import { addItem } from "../../actions/cartActions";
import { useParams, useNavigate } from "react-router-dom";

export default function Product(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const singlePub = useSelector((state) => state.singlePublication);

  const { error, loading, product } = singlePub;
  const { data } = product;

  const [img, setimg] = useState(null);
  const [expand, setExpand] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");

  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    dispatch(singlePublication(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!data) return;
    const firstImg = data.product_media.find((media) => {
      return !media.video;
    }).img;
    setimg(firstImg);
  }, [data]);

  const addToCart = () => {
    if (mediaType === null && data.media_types) {
      setErr("please select a media type");
      return;
    }
    dispatch(addItem(id, mediaType));
    navigate(`/cart`);
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1> ERROR </h1>
      ) : data ? (
        <>
          {/* title and author in div above  */}

          <div className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            <h1 className="mb-5">
              <span>{data.title}</span> {data.author ? "-" : ""}{" "}
              <span>{data.author}</span>
            </h1>
          </div>

          <div className="flex flex-col md:flex-row px-3">
            <div className="flex flex-col w-full md:w-2/3 align-items-center">
              <img
                src={img}
                className="w-full"
                alt={`${data.title}cover art`}
              />
              <div className="flex flex-row mt-5">
                {data.product_media.map((img, i) => {
                  if (img.img) {
                    return (
                      <div key={`img${i}`} className="w-2/12 hover:border-2">
                        <img
                          src={img.img}
                          alt={`${data.title} view ${i}`}
                          onClick={() => setimg(img.img)}
                        />
                      </div>
                    );
                  } else return "";
                })}
              </div>
              {data.product_media.map((img, i) => {
                if (img.video) {
                  return (
                    <iframe
                      className="mt-5"
                      key={i}
                      width="426"
                      height="240"
                      src={img.video}
                      title="YouTube video player"
                      allowFullScreen
                    ></iframe>
                  );
                } else return "";
              })}
            </div>
            <div className="md:w-3/4 p-5 subTitle">
              <div>
                {data.description.length >= 1200 ? (
                  <>
                    {expand === false ? (
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {data.description.slice(0, 1200)}
                        <br />
                        <span
                          className="title text-zinc-400 hover:text-teal-400"
                          onClick={(e) => setExpand(!expand)}
                        >
                          ... Read More
                        </span>
                      </p>
                    ) : (
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {data.description}
                        <br />
                        <span
                          className="title text-zinc-400 hover:text-teal-400"
                          onClick={(e) => setExpand(!expand)}
                        >
                          ... Read Less
                        </span>
                      </p>
                    )}
                  </>
                ) : data ? (
                  <>
                    <p style={{ whiteSpace: "pre-wrap" }}>{data.description}</p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-4">
                <p style={{ whiteSpace: "pre-wrap" }}>{data.format}</p>
                <p> $ {data.price}</p>
                <br />
              </div>
              {data.quantity > 0 ? (
                <div>
                  {err ? <p className="text-red-500">{err}</p> : ""}
                  {data.media_types ? (
                    <div onMouseLeave={(e) => setShow(false)}>
                      <label
                        onMouseEnter={(e) => setShow(true)}
                        onClick={(e) => setShow(!show)}
                        className="title w-2/3 md:w-1/3 p-3 border-2 border-black"
                      >
                        Format: {mediaType}
                      </label>
                      {data.media_types.split(",").map((item, i) => {
                        return (
                          <p
                            className={`w-2/3 md:w-1/3 p-3 hover:bg-black hover:text-white ${
                              show ? "" : "hidden"
                            } border-r-2 border-l-2 border-black ${
                              i === data.media_types.split(",").length - 1
                                ? "border-b-2"
                                : ""
                            }`}
                            key={`option${item}`}
                            onClick={(e) => setMediaType(item)}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={addToCart}
                    className="p-3 mt-3 hover:bg-black hover:text-white border-2 border-black title"
                  >
                    Add to Cart
                  </button>
                </div>
              ) : (
                <p>
                  <strong>out of stock</strong>
                </p>
              )}{" "}
            </div>
          </div>
        </>
      ) : error ? (
        <h1>Error</h1>
      ) : (
        ""
      )}
    </div>
  );
}
