import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editSiteData, createSiteData } from "../../actions/siteDataActions";
import ExternalLinkEditor from "./ExternalLinkEditor";

export default function PageEditModule({
  aboutData,
  contactData,
  id,
  links,
  newPage,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const text = useRef();

  const [newLinks, setNewLinks] = useState(0);

  let page = aboutData ? aboutData.id : contactData ? contactData.id : newPage;

  const create = (e) => {
    e.preventDefault();
    dispatch(createSiteData({ text: text.current.value, page }));
  };

  const save = (e) => {
    e.preventDefault();
    dispatch(editSiteData({ text: text.current.value }, id));
    if (aboutData) {
      navigate("/about");
    } else if (contactData) {
      navigate("/contact");
    }
  };

  const manageNewLinks = () => {
    if (newLinks < 1) return;
    setNewLinks(newLinks - 1);
  };
  return (
    <>
      <form>
        {aboutData ? (
          <div className="flex flex-col">
            <textarea
              style={{ whiteSpace: "pre-wrap" }}
              rows="10"
              className="border-1 border-black p-3"
              ref={text}
              defaultValue={aboutData.text}
              required
            />
            <button
              className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/2 md:w-1/5"
              onClick={save}
            >
              Save
            </button>
          </div>
        ) : contactData ? (
          <></>
        ) : newPage ? (
          <>
            <input
              style={{ whiteSpace: "pre-wrap" }}
              as="textarea"
              rows="4"
              ref={text}
              required
            />
            <button className="main-btn mt-2" onClick={create}>
              Save
            </button>
          </>
        ) : (
          ""
        )}
      </form>

      {page !== newPage ? (
        <>
          {links.map((link) => {
            return (
              <div className="mt-3" key={`link${link.id}`}>
                <ExternalLinkEditor data={link} />
              </div>
            );
          })}
          {newLinks > 0
            ? [...Array(newLinks)].map((i) => {
                return (
                  <div className="mt-3" key={`newLink${i}`}>
                    <ExternalLinkEditor
                      data=""
                      newlinks={newLinks}
                      action={setNewLinks}
                      page={page}
                    />
                  </div>
                );
              })
            : ""}
        </>
      ) : (
        ""
      )}

      <div className="mt-2">
        <button
          className="p-2 w-12 h-12 border-1 border-black hover:bg-black hover:text-white"
          onClick={manageNewLinks}
        >
          -
        </button>
        <button
          className="p-2 w-12 h-12 border-1 border-black hover:bg-black hover:text-white"
          onClick={(e) => setNewLinks(newLinks + 1)}
        >
          +
        </button>
      </div>
    </>
  );
}
