import React from "react";
export default function Shippinginfo() {
  return (
    <div className="p-10 pt-24 subTitle">
      <div className="mt-5">
        <h1 className="text-4xl">Shipping</h1>
        <p className="mt-5">
          {" "}
          All shipments are mailed via USPS and charged on a flat rate of $7 USD
          for domestic and $30 USD for international.
        </p>
        <p>
          {" "}
          Please allow 3-4 weeks for items to ship after the date of purchase.
        </p>
        <p>
          {" "}
          Please contact{" "}
          <a href="mailto:Randommaneditions@gmail.com">
            {" "}
            randommaneditions@gmail.com{" "}
          </a>{" "}
          with any question or concerns.{" "}
        </p>
      </div>
    </div>
  );
}
