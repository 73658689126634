import axios from "axios";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/auth/login",
      {
        username: username,
        password: password,
      },
      config
    );

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });

    localStorage.setItem("loginInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: "LOGIN_FAILED",
      payload: err.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("loginInfo");
  dispatch({ type: "LOGOUT" });
};
