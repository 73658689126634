import React, { useState } from "react";

export default function PubSort({ pubFilter, setPubFilter, sort, setSort }) {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  return (
    <div className="flex flex-col">
      <div
        className="absolute z-10 pt-32 left-10 w-1/3 lg:w-1/6"
        onMouseEnter={(e) => setShowFilter(true)}
        onMouseLeave={(e) => setShowFilter(false)}
        onClick={(e) => setShowFilter(!showFilter)}
      >
        <p className="p-2 title border-2 border-black">
          Filter:{" "}
          <span className="subTitle pl-2">{pubFilter ? pubFilter : ""}</span>
        </p>
        {showFilter ? (
          <div className="bg-white">
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-black"
              onClick={(e) => setPubFilter(null)}
            >
              All
            </p>
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-black"
              onClick={(e) => setPubFilter("Still")}
            >
              Still
            </p>
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-b-2 border-black"
              onClick={(e) => setPubFilter("Moving")}
            >
              Moving
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="absolute z-10 pt-32 left-64 sm:left-72 md:left-96 w-1/3 lg:w-1/6"
        onMouseEnter={(e) => setShowSort(true)}
        onMouseLeave={(e) => setShowSort(false)}
        onClick={(e) => setShowSort(!showSort)}
      >
        <p className="p-2 title border-2 border-black">
          Sort: <span className="subTitle pl-2">{sort ? sort : ""}</span>
        </p>
        {showSort ? (
          <div className="bg-white">
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-black"
              onClick={(e) => setSort("Newest")}
            >
              Newest
            </p>
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-black"
              onClick={(e) => setSort("Author")}
            >
              Author
            </p>
            <p
              className="p-2 hover:bg-black hover:text-white border-l-2 border-r-2 border-b-2 border-black"
              onClick={(e) => setSort("Title")}
            >
              Title
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
