export const publicationListReducer = (
  state = { publications: [] },
  action
) => {
  switch (action.type) {
    case "PUBLICATION_LIST_REQUEST":
      return { loading: true, publications: [] };
    case "PUBLICATION_LIST_SUCCESS":
      return { loading: false, publications: action.payload };
    case "PUBLICATION_LIST_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const singlePublicationReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case "PUBLICATION_REQUEST":
      return { loading: true, product: {} };
    case "PUBLICATION_RESET":
      return { loading: false, product: {} };
    case "PUBLICATION_SUCCESS":
      return { loading: false, product: action.payload };
    case "PUBLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "CREATE_PUBLICATION_REQUEST":
      return { loading: true, product: {} };
    case "CREATE_PUBLICATION_SUCCESS":
      return { loading: false, product: {} };
    case "CREATE_PUBLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "EDIT_PUBLICATION_REQUEST":
      return { loading: true, product: {} };
    case "EDIT_PUBLICATION_SUCCESS":
      return { loading: false, product: action.payload };
    case "EDIT_PUBLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_PUBLICATION_REQUEST":
      return { loading: true, product: {} };
    case "DELETE_PUBLICATION_SUCCESS":
      return { loading: false, product: action.payload };
    case "DELETE_PUBLICATION_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const publicationMediaReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_PUBLICATION_MEDIA_REQUEST":
      return { loading: true, media: {} };
    case "ADD_PUBLICATION_MEDIA_SUCCESS":
      return { loading: false, media: action.payload };
    case "ADD_PUBLICATION_MEDIA_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_PUBLICATION_MEDIA_REQUEST":
      return { loading: true, media: {} };
    case "DELETE_PUBLICATION_MEDIA_SUCCESS":
      return { loading: false, media: action.payload };
    case "DELETE_PUBLICATION_MEDIA_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
