import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  editExternalLink,
  getSiteData,
  createExternalLink,
  deleteExternalLink,
} from "../../actions/siteDataActions";

export default function ExternalLinkEditor(props) {
  const dispatch = useDispatch();

  const linkName = useRef();
  const url = useRef();

  const save = async () => {
    if (props.data) {
      try {
        await dispatch(
          editExternalLink(
            {
              text: linkName.current.value,
              url: url.current.value,
            },
            props.data.id
          )
        );
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch(getSiteData());
      }
    } else {
      try {
        await dispatch(
          createExternalLink({
            page: props.page,
            text: linkName.current.value,
            url: url.current.value,
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch(getSiteData());
      }
    }
  };
  const removeLink = async () => {
    try {
      await dispatch(deleteExternalLink(props.data.id));
    } catch (err) {
      console.error(err);
    } finally {
      await dispatch(getSiteData());
    }
  };
  return (
    <div className="p-2 border-1 border-black">
      <form>
        <div className="flex flex-col m-3">
          <label>Display Text</label>
          <input
            type="text"
            className="p-2 ml-2 border-1 border-black md:w-1/3"
            ref={linkName}
            defaultValue={props.data.text || ""}
            placeholder={"text"}
            required
          />

          <label>URL</label>
          <input
            type="text"
            className="p-2 ml-2 border-1 border-black md:w-1/3"
            ref={url}
            defaultValue={props.data.url || ""}
            placeholder={"url"}
            required
          />
        </div>

        <div className="p-2 ml-2 flex flex-row">
          <button
            className="p-2 border-1 border-black hover:bg-black hover:text-white w-1/5"
            onClick={save}
          >
            save
          </button>

          {props.data ? (
            <button
              className="p-2 border-1 border-red-500 text-red-500 md:ml-5 hover:bg-red-500 hover:text-white w-1/5"
              onClick={removeLink}
            >
              X
            </button>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
}
