import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Link } from "react-router-dom";
import HomeProductCard from "../../components/HomepageProductCard";

import { useDispatch, useSelector } from "react-redux";
import { listPublications } from "../../actions/publicationActions";
import VideoSplash from "../../components/VideoSplash";
import { TbArrowWaveRightUp } from "react-icons/tb";

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPublications());
    window.addEventListener("scroll", controlAnimation);
    if (window.visualViewport.width < 800) {
      setShow(true);
    }
    return () => {
      window.removeEventListener("scroll", controlAnimation);
    };
  }, [dispatch]);

  const publicationList = useSelector((state) => state.publicationList);
  const { error, loading, publications } = publicationList;

  const [show, setShow] = useState(false);

  const animate = useSpring({
    opacity: show ? 1 : 0,
    config: { duration: 100 },
  });

  const controlAnimation = () => {
    if (
      window.scrollY > window.visualViewport.height + 50 ||
      window.visualViewport.width < 900
    ) {
      setShow(true);
    } else setShow(false);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {/* / this will be the splash video */}
      <VideoSplash />
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h3> ERROR</h3>
      ) : (
        <div className="relative top-40">
          <h1 className="relative top-24 p-10 text-6xl text-teal-400 text-right drop-shadow-lg mb-20">
            Latest Releases
          </h1>
          <animated.div style={animate}>
            <div>
              {publications.slice(0, 4).map((product, i) => (
                <div key={product.id}>
                  {product.product_media.find((media) => media.img) ? (
                    <HomeProductCard product={product} link={product.id} />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </animated.div>
        </div>
      )}
      <div className="mt-36">
        <Link
          to="/publications"
          className="p-10 text-4xl text-black flex flex-row justify-center"
        >
          <h1 className="hover:text-yellow-300">View More</h1>
          <TbArrowWaveRightUp />
        </Link>
      </div>
    </div>
  );
}
