import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, addTotals } from "../../actions/cartActions";
import { useNavigate, Link } from "react-router-dom";
import { FiX } from "react-icons/fi";

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { cartItems, loading, error } = cart;

  const [subtotal, setSubtotal] = useState(null);
  const [shipping, setShipping] = useState(7);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    if (cartItems) {
      let num = 0.0;
      for (let i = 0; i < cartItems.length; i++) {
        num = num + parseFloat(cartItems[i].price) * cartItems[i].qty;
      }

      setSubtotal(parseFloat(num).toFixed(2));

      num += shipping;

      setTotal(parseFloat(num).toFixed(2));
    } else return;
  }, [cartItems, shipping, dispatch]);

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };

  const handleNext = (e) => {
    e.preventDefault();
    dispatch(
      addTotals(
        Number(subtotal).toFixed(2),
        Number(shipping).toFixed(2),
        Number(total).toFixed(2)
      )
    );
    navigate("/shipping");
  };
  return (
    <div className="pt-5">
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1>ERROR</h1>
      ) : cartItems ? (
        <div className="p-10 pt-20">
          <h3 className="text-4xl title"> Your Cart</h3>
          <div>
            <div className="mt-10 border-1 border-black">
              {cartItems
                ? cartItems.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={`py-2 flex flex-row justify-around align-items-center ${
                          i % 2 === 0 ? "bg-slate-200" : ""
                        }`}
                      >
                        <div className="md:p-2">
                          <Link to={`/product/${item.product}`}>
                            <img
                              className="h-24 md:h-32 w-full border-1"
                              src={item.img}
                              alt="cart item"
                            />
                          </Link>
                        </div>
                        <div className="md:w-1/5">
                          <h5 className="title text-sm ml-3 md:text-2xl">
                            {item.name}
                            {item.format ? <span> - {item.format} </span> : ""}
                          </h5>
                        </div>
                        <div className="md:w-1/5">
                          <p>qty: {item.qty}</p>
                        </div>
                        <div className="md:w-1/5">
                          <p>
                            Price: $
                            {parseFloat(item.price * item.qty).toFixed(2)}
                          </p>
                        </div>

                        <button onClick={() => handleRemove(item.product)}>
                          <FiX className="text-xl md:text-3xl text-red-500 border-1 border-red-500 rounded-md" />
                        </button>
                      </div>
                    );
                  })
                : ""}
            </div>

            <div>
              <div style={{ padding: "15px" }}>
                <p className="title"> Subtotal: $ {subtotal}</p>
                <p className="title mt-3"> Shipping Options:</p>
                <form className="my-3">
                  <select
                    className="bg-black text-white p-3 subTitle"
                    onChange={(e) => {
                      setShipping(Number(e.target.value));
                    }}
                  >
                    <option value={7}>domestic - $7</option>
                    <option value={30}>international - $30</option>
                  </select>
                </form>
                <p className="mt-3 title"> Total: $ {total}</p>
                <button
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                  disabled={cartItems.length < 1}
                  onClick={handleNext}
                >
                  Check out
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
