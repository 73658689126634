import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getSiteData } from "../../actions/siteDataActions";
import PageEditModule from "../../components/adminComponents/PageEditModule";

export default function SiteEditor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.userLogin);

  const { loginInfo } = user;

  useEffect(() => {
    if (!loginInfo) navigate("/");
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    auth();
    dispatch(getSiteData());
  }, [loginInfo, navigate, dispatch]);

  const siteData = useSelector((state) => state.siteData);

  const aboutData = siteData.data.filter((item) => item.page === "about");
  const contactData = siteData.data.filter((item) => item.page === "contact");

  return (
    <div className="p-10 md:p-20">
      <h1 className="title text-3xl mt-20 md:mt-10"> Site-Editor</h1>

      <div className="md:m-10">
        <h2 className="title text-2xl m-3">About</h2>
        {aboutData[0] ? (
          <PageEditModule
            aboutData={aboutData[0]}
            links={aboutData[0].links}
            id={aboutData[0].id}
          />
        ) : (
          <PageEditModule aboutData={""} links={[]} id={""} newPage="about" />
        )}
      </div>

      <div className="m-3">
        <h2 className="title text-2xl m-3">Contact</h2>
        {contactData[0] ? (
          <PageEditModule
            contactData={contactData[0]}
            links={contactData[0].links}
            id={contactData[0].id}
          />
        ) : (
          <PageEditModule
            contactData={""}
            links={[]}
            id={""}
            newPage="contact"
          />
        )}
      </div>
    </div>
  );
}
