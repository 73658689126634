import React, { useState } from "react";

import { Link } from "react-router-dom";

export default function Productdiv(props) {
  const firstImg = props.product.product_media.find((media) => {
    return media.img;
  }).img;
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={`my-5 ${focus ? "border-2" : ""}`}
      onMouseEnter={(e) => setFocus(true)}
      onMouseLeave={(e) => setFocus(false)}
    >
      <Link to={`/product/${props.link}`} className="text-black">
        <img className="mb-2" src={firstImg} alt="product" />
        <div
          className={`p-1 text-center ${
            focus ? "bg-black text-white ease-in duration-200" : ""
          }`}
        >
          <h4 className="title">
            {props.product.title.length > 35
              ? props.product.title.slice(0, 35) + "..."
              : props.product.title}
          </h4>

          <p className="proddivAuth mb-3 titles">
            {/^various/i.test(props.product.author)
              ? ""
              : props.product.author.length > 45
              ? `${props.product.author.slice(0, 45)}...`
              : `${props.product.author}`}
          </p>
        </div>
      </Link>
    </div>
  );
}
