import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function OrderComplete() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderState = useSelector((state) => state.order);

  const { order, loading, error } = orderState;

  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const setup = async () => {
      try {
        setOrderData(order);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch({ type: "ORDER_CREATE_RESET" });
      }
    };
    if (order) {
      setup();
    }
  }, [order, dispatch]);

  const home = (e) => {
    e.preventDefault();
    navigate("/");
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1> ERROR </h1>
      ) : orderData ? (
        <div className="p-10 pt-24">
          <div>
            <h1 className="text-3xl"> Order Confirmation</h1>
          </div>
          <div className="flex flex-row">
            <div className="md:w-2/3">
              <p className="py-3 text-2xl">Thank you for your purchase</p>
              <p>
                you will recieve an email with a recipt and another with
                tracking information upon shipment.
              </p>
              <p> Please allow 3-4 weeks for shipping.</p>
              <div>
                <div>
                  <h3 className="title text-2xl mt-3"> Items </h3>
                  <div className="border-1 border-black">
                    {orderData
                      ? orderData.order_items.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className={`p-3 flex flex-row justify-between ${
                                i % 2 === 0 ? "bg-zinc-200" : ""
                              }`}
                            >
                              <h3 className="w-1/3">{item.name}</h3>
                              <h3>Qty: {item.quantity}</h3>
                              <h3>
                                ${(item.price * item.quantity).toFixed(2)}
                              </h3>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="py-3 flex flex-row">
                    <p>
                      <span className="title">Shipping:</span> $
                      {orderData ? orderData.shipping_price : ""}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p>
                      <span className="title">GrandTotal:</span> $
                      {orderData ? orderData.total_price : ""}
                    </p>
                  </div>
                </div>
                {/* shipping info div */}
                <div>
                  <h3 className="mt-5 title">Shipping info</h3>
                  <div className="mt-3">
                    <h4>
                      {orderData
                        ? orderData.first_name + " " + orderData.last_name
                        : ""}
                    </h4>
                    <h5>
                      {orderData ? orderData.shipping_address.address : ""}
                    </h5>
                    <h5>
                      {orderData
                        ? orderData.shipping_address.city +
                          " " +
                          orderData.shipping_address.state
                        : ""}
                    </h5>
                    <h5>
                      {orderData
                        ? orderData.shipping_address.postalCode +
                          " " +
                          orderData.shipping_address.country
                        : ""}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex justify-center md:w-1/3">
              <img
                src="https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo.png"
                alt="logo"
              />
            </div>
          </div>

          <button
            className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
            onClick={home}
          >
            Home
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
