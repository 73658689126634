import React from "react";

export default function PaymentDetails({ order }) {
  return (
    <>
      <div className="mt-3 flex flex-row justify-between">
        <h4 className="title">Subtotal: </h4>
        <h5>
          {order
            ? `$${(
                parseFloat(order.total_price) - parseFloat(order.shipping_price)
              ).toFixed(2)}`
            : ""}
        </h5>
      </div>
      <div className="flex flex-row justify-between">
        <h4 className="title">Shipping: </h4>
        <h5>{order ? `$${order.shipping_price}` : ""}</h5>
      </div>
      <div className="flex flex-row justify-between border-t border-black mt-2">
        <h4 className="title">Total:</h4>
        <h5> {order ? `$ ${order.total_price}` : ""}</h5>
      </div>
    </>
  );
}
