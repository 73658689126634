import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { orderList } from "../../actions/orderActions";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Orders(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders = useSelector((state) => state.orderList);
  const user = useSelector((state) => state.userLogin);

  const { loginInfo } = user;
  const { data } = orders;

  useEffect(() => {
    if (!loginInfo) navigate("/"); // if there is no user info in the redux state redirect
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    // if there is user info check the token is valid
    auth();
    dispatch(orderList());
  }, [loginInfo, navigate, dispatch]);

  return (
    <div>
      <div className="pt-24">
        <h1 className="p-10 text-3xl">Orders</h1>
        <table className="w-screen">
          <thead>
            <tr className="text-center border-b border-black">
              <td className="title">Customer</td>

              <td className="title">Order Created</td>

              <td className="title">Total</td>

              <td className="title">Fufilled</td>

              <td className="title">Tracking</td>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data
                .filter((item) => item.archived === props.archived)
                .map((order, i) => {
                  return (
                    <tr
                      key={`${i}${order.last_name}`}
                      className={`py-2 ${
                        i % 2 === 0 ? "bg-slate-200" : ""
                      } text-center`}
                    >
                      <td>
                        <Link to={`/orders/${order.id}`}>
                          <strong>
                            {order.first_name} {order.last_name}
                          </strong>
                        </Link>
                      </td>

                      <td>{order.created_at.split("T")[0]}</td>
                      <td>
                        <p>${order.total_price}</p>
                      </td>
                      <td>
                        <p className="flex justify-center">
                          {order.delivered ? <AiOutlineCheck /> : ""}
                        </p>
                      </td>

                      <td>
                        {order.tracking ? (
                          <a
                            href={`https://google.com/search?q=${order.tracking}`}
                          >
                            {order.tracking}
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="m-5">
        {props.archived ? (
          <Link
            className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
            to={"/orders"}
          >
            {" "}
            View Orders{" "}
          </Link>
        ) : (
          <Link
            className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
            to={"/archived-orders"}
          >
            View Archived Orders
          </Link>
        )}
      </div>
    </div>
  );
}
