import axios from "axios";

export const createUser = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "CREATE_USER_REQUEST",
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    data = await axios.post(
      "/api/user/create-user",
      {
        name: data.name,
        email: data.email,
        password: data.password,
      },
      config
    );

    dispatch({
      type: "CREATE_USER_SUCCESS",
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: "CREATE_USER_FAIL",
      payload: data,
    });
  }
};
/////////////////////////////////////////////////

export const updatePassword = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "UPDATE_PASSWORD_REQUEST",
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    const responseData = await axios.put(
      "/api/user/update-password",
      {
        password: data.password,
        password_confirm: data.confirm_password,
      },
      config
    );

    dispatch({
      type: "UPDATE_PASSWORD_SUCCESS",
      payload: responseData,
    });
  } catch (err) {
    dispatch({
      type: "UPDATE_PASSWORD_FAILED",
      payload: err.message,
    });
  }
};
