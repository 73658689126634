import React, { useEffect } from "react";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newOrder } from "../../../actions/orderActions";
import { clearCart } from "../../../actions/cartActions";
import PaypalCheckout from "../../../components/PaypalCheckout";
import { Link } from "react-router-dom";

export default function CorfimOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const orderState = useSelector((state) => state.order);

  const { error, success, loading } = orderState;
  const { cartItems, shippingAddress, totals, userInfo } = cart;

  useEffect(() => {
    if (success) {
      navigate("/order-complete");
      dispatch(clearCart());
    }
  }, [success, navigate, dispatch]);

  const submitOrder = (orderId) => {
    dispatch(
      newOrder({
        cartItems: cartItems,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        shippingPrice: parseFloat(totals.shippingcost).toFixed(2),
        totalPrice: parseFloat(totals.grandtotal).toFixed(2),
        shippingAddress: shippingAddress,
        paypalId: orderId,
      })
    );
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1> ERROR - refresh and try again</h1>
      ) : cartItems ? (
        <div className="p-10 pt-24">
          <h1 className="text-3xl"> Order Confirmation</h1>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/3 p-3">
              <div className="border-1 border-black">
                {cartItems.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`p-1 md:p-3 flex flex-row justify-between align-items-center ${
                        i % 2 === 0 ? "bg-zinc-200" : ""
                      }`}
                    >
                      <div className="flex flex-col md:flex-row align-items-center">
                        <img
                          className="h-24 md:pl-3 border-1"
                          src={item.img}
                          alt={item.title}
                        />
                        <p className="md:p-3 title">
                          {item.name}
                          {item.format ? ` - ${item.format}` : ""}
                        </p>
                      </div>
                      <div>qty: {item.qty}</div>
                      <div className="md:p-3">
                        ${(item.price * item.qty).toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="py-3">
                <div className="flex flex-row">
                  <h3 className="title">Subtotal: </h3>
                  <p className="ml-2"> ${totals.subtotal}</p>
                </div>
                <div className="flex flex-row">
                  <h3 className="title">Shipping:</h3>
                  <p className="ml-2"> ${totals.shippingcost}</p>
                </div>
                <div className="flex flex-row">
                  <h3 className="title">Grandtotal:</h3>
                  <p className="ml-2">
                    <strong>${totals.grandtotal} </strong>
                  </p>
                </div>
              </div>
              <Link
                to="/shipping"
                className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
              >
                Back
              </Link>
            </div>
            <div className="md:w-1/3 p-3">
              <h3 className="title text-xl"> Shipping info</h3>
              <div className="my-3">
                <h4>
                  {userInfo.firstName} {userInfo.lastName}
                </h4>
                <h5>{shippingAddress.streetAddress}</h5>
                <h5>
                  {shippingAddress.city} {shippingAddress.state}
                </h5>
                <h5>
                  {shippingAddress.postalCode} {shippingAddress.country}
                </h5>
              </div>
              <div>
                {cart ? (
                  <PaypalCheckout order={cart} submitOrder={submitOrder} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
