import React, { useEffect } from "react";
import { getSiteData } from "../../actions/siteDataActions";
import { useSelector, useDispatch } from "react-redux";

export default function About() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteData());
  }, [dispatch]);

  const pageData = useSelector((state) => state.siteData);

  const { data, loading, error } = pageData;

  const aboutData = data.filter((item) => item.page === "about");

  return (
    <div className="pt-5">
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1>ERROR</h1>
      ) : data ? (
        <div className="p-10 pt-24">
          <h1 className="text-4xl">About</h1>
          {aboutData[0] ? (
            <>
              <p className="mt-5" style={{ whiteSpace: "pre-wrap" }}>
                {aboutData[0].text}
              </p>
              <div className="my-5">
                <a
                  className="title hover:text-lime-500"
                  href="https://www.paypal.com/donate/?hosted_button_id=96ZCB22SGZ6DA"
                >
                  DONATE
                </a>
              </div>

              <ul className="friends-list">
                {aboutData[0]
                  ? aboutData[0].links.map((link, i) => {
                      return (
                        <li key={`link${i}`}>
                          <a
                            className="title hover:text-teal-500"
                            href={link.url}
                          >
                            {" "}
                            {link.text}{" "}
                          </a>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
