import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addShippingAddress, addUserInfo } from "../../../actions/cartActions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Shipping() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress, loading, error } = cart;

  const streetAddress = useRef();
  const city = useRef();
  const state = useRef();
  const [country, setCountry] = useState("US");
  const postalCode = useRef();
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();

  const handleNext = (e) => {
    e.preventDefault();
    dispatch(
      addUserInfo({
        firstName: firstName.current.value,
        lastName: lastName.current.value,
        email: email.current.value,
      })
    );
    dispatch(
      addShippingAddress({
        streetAddress: streetAddress.current.value,
        city: city.current.value,
        state: state.current.value,
        postalCode: postalCode.current.value,
        country: country,
      })
    );
    navigate("/confirm-order");
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center align-center">
          <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
            L o a d i n g . . .
          </p>
        </div>
      ) : error ? (
        <h1> ERROR </h1>
      ) : cart ? (
        <div className="p-10 pt-24 flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <h2 className="title text-3xl"> Shipping Information</h2>
            <form
              className="mt-10 border-1 border-black p-3"
              onSubmit={handleNext}
            >
              <div className="flex flex-col">
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> First Name </label>
                  <input
                    className="ml-3 p-1 border-1 border-black"
                    required
                    type="string"
                    ref={firstName}
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> Last Name </label>
                  <input
                    className="ml-3 p-1 border-1 border-black"
                    required
                    type="string"
                    ref={lastName}
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> Email </label>
                  <input
                    className="ml-3 p-1 border-1 border-black"
                    required
                    type="email"
                    ref={email}
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> Street Address </label>
                  <input
                    className="ml-3 p-1 border-1 border-black"
                    required
                    type="string"
                    ref={streetAddress}
                    defaultValue={
                      shippingAddress ? shippingAddress.streetAddress : ""
                    }
                  />
                </div>

                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> City </label>
                  <input
                    required
                    className="ml-3 p-1 border-1 border-black"
                    type="string"
                    ref={city}
                    defaultValue={shippingAddress ? shippingAddress.city : ""}
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> State </label>
                  <input
                    required
                    className="ml-3 p-1 border-1 border-black"
                    type="string"
                    ref={state}
                    defaultValue={shippingAddress ? shippingAddress.state : ""}
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  <label className="w-1/2 subTitle"> Postal Code / Zip </label>
                  <input
                    required
                    className="ml-3 p-1 border-1 border-black"
                    type="string"
                    ref={postalCode}
                    defaultValue={
                      shippingAddress ? shippingAddress.postalCode : ""
                    }
                  />
                </div>
                <div className="my-2 flex flex-row justify-evenly">
                  {cart.totals.shippingcost === "7.00" ? (
                    ""
                  ) : (
                    <>
                      <label className="w-1/2 subTitle"> Country Code </label>
                      <input
                        required
                        className="ml-3 p-1 border-1 border-black"
                        type="string"
                        onChange={(e) => setCountry(e.target.value)}
                        defaultValue={
                          shippingAddress ? shippingAddress.country : ""
                        }
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <Link
                  to="/cart"
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                >
                  Back
                </Link>
                <button
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
          <div className="md:w-1/2 p-5">
            <p className="mt-5">
              All shipments are mailed via USPS and charged on a flat rate of $7
              USD for domestic and $30 USD for international.
            </p>
            <br />
            <p>
              Please allow 3-4 weeks for items to ship after the date of
              purchase.
            </p>
            <br />

            <p>
              Please contact{" "}
              <a
                className="hover:text-pink-500"
                href="mailto:Randommaneditions@gmail.com"
              >
                randommaneditions@gmail.com
              </a>{" "}
              with any question concerns.{" "}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
