export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const item = action.payload;
      const foundItem = state.cartItems.find((x) => x.product === item.product);

      if (foundItem) {
        foundItem.qty++;
        const update = state.cartItems.map((x) =>
          x.product === foundItem.product ? foundItem : x
        );
        return {
          ...state,
          cartItems: update,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case "REMOVE_ITEM":
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== action.payload),
      };

    case "ADD_TOTALS":
      return {
        ...state,
        totals: action.payload,
      };

    case "ADD_SHIPPING_ADDRESS":
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case "ADD_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };

    case "CLEAR_CART":
      return {
        cartItems: [],
        shippingAddress: {},
        totals: {},
        userInfo: {},
      };

    default:
      return state;
  }
};
