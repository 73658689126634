import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/adminActions";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
export default function Header() {
  const creds = useSelector((state) => state.userLogin);
  const { loginInfo } = creds;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    if (hamburger) {
      setHamburger(!hamburger);
    }
    dispatch(logout());
  };

  const [refresh, setRefesh] = useState(false);
  const [show, setShow] = useState(false);
  const [hamburger, setHamburger] = useState(false);

  useEffect(() => {}, [refresh]);

  const logoArr = [
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo_dark.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo_dark_cyan.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo_dark_yellow.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo_dark_magenta.png",
  ];

  return (
    <div className="fixed bg-black h-20 w-screen flex flex-row justify-between align-items-center z-20">
      <Link className="" to="/" onClick={(e) => setRefesh(!refresh)}>
        <img
          alt="home link"
          src={logoArr[Math.floor(Math.random() * 4)]}
          className="h-20 relative left-3.5"
        />
      </Link>
      {loginInfo ? (
        <div className="w-full justify-around hidden md:flex flex-row">
          <Link to="/publications">
            <h1 className="p-2 text-teal-400 hover:text-white text-3xl">
              Publications
            </h1>
          </Link>
          <Link to="/new-publication">
            <h1 className="p-2 text-pink-500 hover:text-white text-3xl">New</h1>
          </Link>
          <Link to="/orders">
            <h1 className="p-2 text-yellow-300 hover:text-white text-3xl">
              Orders
            </h1>
          </Link>
          <div onMouseEnter={(e) => setShow(true)}>
            <h1 className="p-2 text-lime-400  hover:text-white text-3xl">
              More...
            </h1>
            {show ? (
              <div
                onMouseLeave={(e) => setShow(false)}
                className="absolute top-20 right-0 w-1/4 h-46 p-5 bg-white border-2 border-black"
              >
                <Link to="/edit-site">
                  <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
                    Site Editor
                  </h1>
                </Link>
                <Link to="/user-panel">
                  <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
                    Users
                  </h1>
                </Link>
                <h1
                  className="p-2 text-3xl hover:bg-black hover:text-white"
                  onClick={logoutHandler}
                >
                  Log Out
                </h1>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="w-full justify-around hidden md:flex flex-row">
          <Link to="/publications">
            <h1 className="p-2 text-teal-400 hover:text-white text-3xl">
              {" "}
              Publications
            </h1>
          </Link>
          <Link to="/cart">
            <h1 className="p-2 text-pink-500 hover:text-white text-3xl">
              {" "}
              Cart
            </h1>
          </Link>
          <Link to="/about">
            <h1 className="p-2 text-yellow-300 hover:text-white text-3xl">
              {" "}
              About
            </h1>
          </Link>
          <Link to="/contact">
            <h1 className="p-2 text-lime-400  hover:text-white text-3xl">
              {" "}
              Contact
            </h1>
          </Link>
        </div>
      )}
      <div
        className="text-yellow-500 text-6xl md:hidden p-2"
        onClick={(e) => setHamburger(!hamburger)}
      >
        <GiHamburgerMenu />
      </div>
      {hamburger && loginInfo ? (
        <div className="absolute top-20 w-screen h-screen bg-white">
          <Link to="/publications" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
              Publications
            </h1>
          </Link>
          <Link to="/new-publication" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
              New
            </h1>
          </Link>
          <Link to="/orders" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
              Orders
            </h1>
          </Link>
          <Link to="/edit-site" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
              Site Editor
            </h1>
          </Link>
          <Link to="/user-panel" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-3xl hover:bg-black hover:text-white">
              Users
            </h1>
          </Link>
          <h1
            className="p-2 text-3xl hover:bg-black hover:text-white"
            onClick={logoutHandler}
          >
            Log Out
          </h1>
        </div>
      ) : hamburger && !loginInfo ? (
        <div className="absolute top-20 w-screen h-screen bg-white text-center">
          <Link to="/publications" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-teal-400 text-3xl hover:text-white hover:bg-black">
              {" "}
              Publications
            </h1>
          </Link>
          <Link to="/cart" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-pink-500 text-3xl hover:text-white hover:bg-black">
              {" "}
              Cart
            </h1>
          </Link>
          <Link to="/about" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-yellow-300 text-3xl hover:text-white hover:bg-black">
              {" "}
              About
            </h1>
          </Link>
          <Link to="/contact" onClick={(e) => setHamburger(!hamburger)}>
            <h1 className="p-2 text-lime-400 text-3xl hover:text-white hover:bg-black">
              {" "}
              Contact
            </h1>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
