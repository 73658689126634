import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { login } from "../../actions/adminActions";

export default function Adminlogin() {
  const username = useRef(null);
  const password = useRef(null);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, loginInfo, error } = userLogin;

  useEffect(() => {
    loginInfo ? setRedirect(true) : setRedirect(false);
  }, [loginInfo, userLogin]);

  const submit = (e) => {
    e.preventDefault();
    dispatch(login(username.current.value, password.current.value));
  };

  if (!redirect) {
    return (
      <div>
        {loading ? (
          <div className="flex justify-center align-center">
            <p className="pl-5 pr-5 md:pl-10 pt-32 titles text-left text-4xl">
              L o a d i n g . . .
            </p>
          </div>
        ) : (
          <div className="pt-24 flex flex-col md:flex-row justify-center align-items-center">
            <div>
              <img
                src="https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo.png"
                alt="randomman logo"
              />
            </div>
            <div className="my-5 shadow-xl md:w-1/2">
              {error ? <h2 className="text-red-500">{error}</h2> : ""}
              <form
                className="p-5 border-1 border-black flex flex-col"
                onSubmit={submit}
              >
                <input type="email" ref={username} placeholder="username" />
                <input
                  className="my-3"
                  type="password"
                  ref={password}
                  placeholder="password"
                />
                <button
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                  type="submit"
                >
                  {" "}
                  Log in
                </button>
              </form>
            </div>
            <div>
              <img
                src="https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/random_man_logo.png"
                alt="randomman logo"
              />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
}
