import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/publicPages/Home";
import About from "./pages/publicPages/About";
import Cart from "./pages/publicPages/Cart";
import Publications from "./pages/publicPages/Publications";
import Product from "./pages/publicPages/Product";
import Contact from "./pages/publicPages/Contact";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Adminlogin from "./pages/adminPages/Adminlogin";
import EditPub from "./pages/adminPages/EditPub";
import NewPub from "./pages/adminPages/NewPub";
import Orders from "./pages/adminPages/Orders";
import SiteEditor from "./pages/adminPages/SiteEditor";
import EditOrders from "./pages/adminPages/EditOrder";
import UsersPanel from "./pages/adminPages/UsersPanel";
import Shipping from "./pages/publicPages/CheckoutPages/Shipping";
import ConfirmOrder from "./pages/publicPages/CheckoutPages/CorfirmOrder";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import OrderComplete from "./pages/publicPages/CheckoutPages/OrderComplete";

import Shippinginfo from "./pages/publicPages/Shippinginfo";
import Returns from "./pages/publicPages/Returns";
import Terms from "./pages/publicPages/Terms";

function App() {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/cart" element={<Cart />} />
            <Route path="/cart/:id" element={<Cart />} />

            {/* checkout routes  */}
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/confirm-order" element={<ConfirmOrder />} />
            <Route path="/order-complete" element={<OrderComplete />} />
            {/* //////////// _______ Footer pages _______/////////// */}
            <Route path="/shipping-info" element={<Shippinginfo />} />
            <Route path="/return-policy" element={<Returns />} />
            <Route path="/terms" element={<Terms />} />
            {/* //////////______ADMIN ROUTES____/////////// */}
            <Route path="/login" element={<Adminlogin />} />

            <Route path="/user-panel" element={<UsersPanel />} />

            <Route path="/new-publication" element={<NewPub />} />
            <Route path="/product/edit/:id" element={<EditPub />} />
            <Route path="/orders" element={<Orders archived={false} />} />
            <Route
              path="/archived-orders"
              element={<Orders archived={true} />}
            />
            <Route path="/orders/:id" element={<EditOrders />} />

            <Route path="/edit-site" element={<SiteEditor />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
