import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function PaypalCheckout(props) {
  const { order, submitOrder } = props;

  const [error, setError] = useState(null);

  const handleApprove = (orderId) => {
    submitOrder(orderId);
  };

  if (error) {
    alert(error);
  }
  return (
    <>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Purchase from Random Man Editions",
                amount: {
                  currency_code: "USD",
                  value: order.totals.grandtotal,
                },
                shipping: {
                  name: {
                    full_name:
                      order.userInfo.firstName + " " + order.userInfo.lastName,
                  },
                  address: {
                    address_line_1: order.shippingAddress.streetAddress,
                    address_line_2: "",
                    admin_area_2: order.shippingAddress.city,
                    admin_area_1: order.shippingAddress.state,
                    postal_code: order.shippingAddress.postalCode,
                    country_code: order.shippingAddress.country,
                  },
                  amount: order.totals.shippingcost,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const createdOrder = await actions.order.capture();
          handleApprove(createdOrder.id);
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal checkout error", err);
        }}
      />
      {error ? <p style={{ color: "red" }}>{error}</p> : ""}
    </>
  );
}
