import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { addressEdit, orderDetails } from "../../actions/orderActions";

export default function ShippingDetails({ order }) {
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);

  const street = useRef();
  const city = useRef();
  const state = useRef();
  const code = useRef();
  const country = useRef();

  const updateAddress = () => {
    dispatch(
      addressEdit(order.shipping_address.id, {
        address: street.current.value,
        city: city.current.value,
        state: state.current.value,
        postalCode: code.current.value,
        country: country.current.value,
      })
    );
    dispatch(orderDetails(order.id));
    setEditing(!editing);
  };
  return (
    <div>
      <h3 className="title"> Shipping Details</h3>
      <h4 className="mt-3">
        {order.first_name} {order.last_name}
      </h4>
      {editing ? (
        <form className="flex flex-col">
          <input
            className="border-t border-x border-black p-2"
            type="string"
            defaultValue={order.shipping_address.address}
            ref={street}
          />
          <input
            className="border-t border-x border-black p-2"
            type="string"
            defaultValue={order.shipping_address.city}
            ref={city}
          />
          <input
            className="border-t border-x border-black p-2"
            type="string"
            defaultValue={order.shipping_address.state}
            ref={state}
          />
          <input
            className="border-t border-x border-black p-2"
            type="string"
            defaultValue={order.shipping_address.postalCode}
            ref={code}
          />
          <input
            className="border-1 border-black p-2"
            type="string"
            defaultValue={order.shipping_address.country}
            ref={country}
          />
        </form>
      ) : (
        <>
          <h5> {order.shipping_address.address} </h5>
          <h5>
            {order.shipping_address.city} {order.shipping_address.state}
          </h5>
          <h5>
            {order.shipping_address.postalCode} {order.shipping_address.country}
          </h5>
        </>
      )}
      {editing ? (
        <div className="flex flex-row justify-between">
          <button
            className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
            onClick={updateAddress}
          >
            Save Changes
          </button>
          <button
            className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
            onClick={() => setEditing(!editing)}
          >
            X
          </button>
        </div>
      ) : (
        <button
          className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
          onClick={() => setEditing(!editing)}
        >
          Edit address
        </button>
      )}
    </div>
  );
}
