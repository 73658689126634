export const siteDataReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case "SITE_DATA_REQUEST":
      return { loading: true, data: [] };
    case "SITE_DATA_SUCCESS":
      return { loading: false, data: action.payload };
    case "SITE_DATA_FAIL":
      return { loading: false, error: action.payload };
    case "SITE_DATA_CREATE_REQUEST":
      return { loading: true, data: [] };
    case "SITE_DATA_CREATE_SUCCESS":
      return { loading: false, data: action.payload };
    case "SITE_DATA_CREATE_FAIL":
      return { loading: false, error: action.payload };
    case "SITE_DATA_EDIT_REQUEST":
      return { loading: true, data: [] };
    case "SITE_DATA_EDIT_SUCCESS":
      return { loading: false, data: action.payload };
    case "SITE_DATA_EDIT_FAIL":
      return { loading: false, error: action.payload };
    case "CREATE_EXTERNAL_LINK_REQUEST":
      return { loading: true, data: [] };
    case "CREATE_EXTERNAL_LINK_SUCCESS":
      return { loading: false, data: action.payload };
    case "CREATE_EXTERNAL_LINK_FAIL":
      return { loading: false, error: action.payload };
    case "EXTERNAL_LINK_EDIT_REQUEST":
      return { loading: true, data: [] };
    case "EXTERNAL_LINK_EDIT_SUCCESS":
      return { loading: false, data: action.payload };
    case "EXTERNAL_LINK_EDIT_FAIL":
      return { loading: false, error: action.payload };
    case "EXTERNAL_LINK_DELETE_REQUEST":
      return { loading: true, data: [] };
    case "EXTERNAL_LINK_DELETE_SUCCESS":
      return { loading: false, data: action.payload };
    case "EXTERNAL_LINK_DELETE_FAIL":
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
