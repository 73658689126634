import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { orderDetails, orderEdit } from "../../actions/orderActions";
import ShippingDetails from "../../components/adminComponents/ShippingDetails";
import PaymentDetails from "../../components/adminComponents/PaymentDetails";
import axios from "axios";

export default function EditOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const orderInfo = useSelector((state) => state.orderDetails);
  const user = useSelector((state) => state.userLogin);

  const [editEmail, setEditEmail] = useState(false);
  const email = useRef();

  const { loginInfo } = user;
  const { order } = orderInfo;

  useEffect(() => {
    if (!loginInfo) navigate("/"); // if there is no user info in the redux state redirect
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    // if there is user info check the token is valid
    auth();
    dispatch(orderDetails(id));
  }, [loginInfo, navigate, id, dispatch]);

  const fulfill = async (e) => {
    e.preventDefault();
    let tracking = prompt("enter tracking number:");
    if (!tracking) return;
    await dispatch(
      orderEdit(id, {
        delivered: true,
        tracking: tracking,
        email: order.email,
      })
    );
    dispatch(orderDetails(id));
  };

  const archive = async (e) => {
    e.preventDefault();
    await dispatch(orderEdit(id, { archived: true }));
    navigate("/orders");
  };

  const updateEmail = (e) => {
    e.preventDefault();
    if (email.current.value !== order.email) {
      dispatch(orderEdit(id, { email: email.current.value }));
    }
    setEditEmail(false);
  };
  return (
    <div>
      {order ? (
        <div className="p-10 pt-24">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="mb-5">
              <h1 className="mb-3">Order # {id}</h1>
              <h2 className="mb-3">
                Paypal ID:{" "}
                <span className="p-2 border-1 border-black text-lg">
                  {order.paypalId}
                </span>
              </h2>
              <h3 className="mb-3">
                Placed :{" "}
                {order.created_at ? order.created_at.split("T")[0] : ""}
              </h3>
              <div>
                <h2>
                  {order.first_name ? order.first_name : ""}{" "}
                  {order.last_name ? order.last_name : ""}
                  {" - "}
                  {order.email && editEmail === false ? (
                    <span
                      className="p-2 hover:bg-black hover:text-white"
                      onClick={(e) => setEditEmail(!editEmail)}
                    >
                      {order.email}
                    </span>
                  ) : editEmail === true ? (
                    <div>
                      <form>
                        <input
                          className="p-2 border-1 border-black "
                          type="string"
                          defaultValue={order.email}
                          ref={email}
                        />
                        <button
                          className="ml-4 p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                          onClick={updateEmail}
                        >
                          Save
                        </button>
                        <button
                          className="ml-4 p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                          onClick={(e) => setEditEmail(!editEmail)}
                        >
                          X
                        </button>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                </h2>
              </div>
            </div>
            <div className="w-1/2">
              {order.shipping_address ? <ShippingDetails order={order} /> : ""}
            </div>
          </div>
          <div className="mt-3 p-2 border-1 border-black">
            <h2 className="title"> Order Summary </h2>
            <table className="w-full">
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Qty</td>
                </tr>
              </thead>
              <tbody>
                {order.order_items ? (
                  order.order_items.map((item, i) => {
                    return (
                      <tr
                        key={`${item.name}${i}`}
                        className={`${i % 2 === 0 ? "bg-slate-200" : ""}`}
                      >
                        <td className="p-2">
                          <p>{item.name}</p>
                        </td>
                        <td className="p-2">
                          <p>{item.quantity}</p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <div>
              <h3 className="title"> Payment Details</h3>
              <div className="w-1/4">
                {order ? <PaymentDetails order={order} /> : ""}
              </div>
              <div>
                {" "}
                {order.tracking ? (
                  <p>
                    <strong>Tracking:</strong> {order.tracking}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {order ? (
                <button
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                  onClick={fulfill}
                >
                  {order.delivered ? "Edit Tracking" : "Mark as Fulfilled"}
                </button>
              ) : (
                ""
              )}{" "}
              {order.delivered ? (
                <button
                  className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
                  disabled={order.archived}
                  onClick={archive}
                >
                  Archive Order
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
