import axios from "axios";

export const listPublications = () => async (dispatch) => {
  try {
    dispatch({ type: "PUBLICATION_LIST_REQUEST" });

    const { data } = await axios.get("/api/products");

    dispatch({
      type: "PUBLICATION_LIST_SUCCESS",
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: "PUBLICATION_LIST_FAIL",
      payload: err,
    });
  }
};

export const singlePublication = (id) => async (dispatch) => {
  try {
    dispatch({ type: "PUBLICATION_REQUEST" });

    const data = await axios.get(`/api/products/${id}`);

    dispatch({
      type: "PUBLICATION_SUCCESS",
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: "PUBLICATION_FAIL",
      payload: err.response,
    });
  }
};

export const singlePublicationReset = () => async (dispatch) => {
  try {
    dispatch({ type: "PUBLICATION_RESET" });
  } catch (err) {
    dispatch({
      type: "PUBLICATIONRESET_FAIL",
      payload: err.response,
    });
  }
};

export const createPublication = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "CREATE_PUBLICATION_REQUEST" });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    const pubData = await axios.post(
      `/api/new/product`,
      {
        title: data["title"],
        author: data["author"],
        format: data["format"],
        media_types: data["media_types"],
        description: data["description"],
        quantity: data["quantity"],
        price: data["price"],
        tag: data["tag"],
        use_firstname: data["use_firstname"],
        use_title: data["use_title"],
      },
      config
    );
    await axios.post(
      "/api/productmedia",
      {
        product: pubData.data.data.id,
        img: data["product_media_img"],
        video: data["product_media_vid"],
      },
      config
    );

    dispatch({
      type: "CREATE_PUBLICATION_SUCCESS",
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: "CREATE_PUBLICATION_FAIL",
      payload: err,
    });
  }
};

export const editPub = (data, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_PUBLICATION_REQUEST" });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    const pubData = await axios.put(
      `/api/product/edit/${id}`,
      {
        title: data["title"],
        author: data["author"],
        format: data["format"],
        media_types: data["media_types"],
        description: data["description"],
        quantity: data["quantity"],
        price: data["price"],
        tag: data["tag"],
        use_firstname: data["use_firstname"],
        use_title: data["use_title"],
      },
      config
    );
    const returnData = await axios.get(`/api/products/${pubData.data.data.id}`);
    dispatch({
      type: "EDIT_PUBLICATION_SUCCESS",
      payload: returnData.data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: "EDIT_PUBLICATION_FAIL",
      payload: err.message,
    });
  }
};

export const deletePublication = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "DELETE_PUBLICATION_REQUEST",
    });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    await axios.delete(`/api/product/edit/${id}`, config);
    dispatch({
      type: "DELETE_PUBLICATION_SUCCESS",
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: "DELETE_PUBLICATION_FAIL",
      payload: err,
    });
  }
};
