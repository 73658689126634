import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser, updatePassword } from "../../actions/userActions";

export default function UsersPanel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.userLogin);
  const adminActions = useSelector((state) => state.adminActions);

  const { loginInfo } = user;

  useEffect(() => {
    if (!loginInfo) navigate("/");
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    auth();
  }, [loginInfo, navigate, dispatch]);

  const newPass = useRef();
  const confirmNewPass = useRef();

  const name = useRef();
  const email = useRef();

  const [passErr, setPassErr] = useState("");

  const updatePass = (e) => {
    e.preventDefault();
    setPassErr("");
    if (newPass.current.value === "" || confirmNewPass.current.value === "")
      return;
    if (newPass.current.value !== confirmNewPass.current.value) {
      setPassErr("passwords do not match");
      return;
    }
    try {
      dispatch(
        updatePassword({
          password: newPass.current.value,
          confirm_password: confirmNewPass.current.value,
        })
      );
    } catch (err) {
      console.error(err);
    }
    newPass.current.value = "";
    confirmNewPass.current.value = "";
  };

  const createNewUser = (e) => {
    e.preventDefault();

    if (name.current.value === "" || email.current.value === "") {
      return;
    }
    try {
      dispatch(
        createUser({
          name: name.current.value,
          email: email.current.value,
          password: "randomman123",
        })
      );
    } catch (err) {
      console.error(err);
    }
    name.current.value = "";
    email.current.value = "";
  };

  return (
    <div className="p-20">
      <div className="mt-10">
        <h1 className="text-3xl">Users Admin</h1>

        <h3 className="title text-xl mt-3"> Update Password</h3>

        <div>
          <form>
            <input
              type="password"
              required
              placeholder="new password"
              ref={newPass}
            />
            <input
              type="password"
              required
              placeholder="confirm password"
              ref={confirmNewPass}
            />
            <button
              className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
              onClick={updatePass}
            >
              Update
            </button>
            {passErr !== "" ? <p className="text-red-500">{passErr}</p> : ""}
            {adminActions.password_error ? (
              <p className="text-red-500">{adminActions.password_error}</p>
            ) : adminActions.password ? (
              <p className="text-green-500">password successfully updated</p>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
      <div className="mt-5">
        <div md={3}>
          <h3 className="title text-xl mt-3"> Create New Staff User</h3>
          <small>
            * all new users start with the password: 'randomman123' and should
            update their password once logged in.
          </small>
        </div>
        <div md={9}>
          <form>
            <input type="string" required placeholder="name" ref={name} />
            <input type="string" required placeholder="e-mail" ref={email} />
            <button
              className="p-2 border-1 border-black my-3 hover:bg-black hover:text-white"
              onClick={(e) => createNewUser()}
            >
              Create
            </button>
            {adminActions.user_error ? (
              <p className="text-red-500">{adminActions.user_error}</p>
            ) : adminActions.user ? (
              <p className="text-green-500">User successfully created</p>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
