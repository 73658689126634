export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_CREATE_REQUEST":
      return {
        loading: true,
      };
    case "ORDER_CREATE_SUCCESS":
      return {
        loading: false,
        success: true,
        order: action.payload.data.data,
      };
    case "ORDER_CREATE_FAIL":
      return {
        loading: false,
        error: action.payload,
      };
    case "ORDER_CREATE_RESET":
      return {};

    default:
      return state;
  }
};

export const orderListReducer = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_LIST_REQUEST":
      return {
        loading: true,
      };
    case "ORDER_LIST_SUCCESS":
      return {
        loading: false,
        data: action.payload,
      };
    case "ORDER_LIST_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, order: {}, orderItem: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case "ORDER_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDER_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case "ORDER_DETAILS_FAIL":
      return {
        loading: false,
        error: action.payload,
      };
    //// payment
    // case "ORDER_PAY_REQUEST":
    //   return {
    //     loading: true,
    //   };
    // case "ORDER_PAY_SUCCESS":
    //   return {
    //     loading: false,
    //     success: true,
    //   };
    // case "ORDER_PAY_FAIL":
    //   return {
    //     loading: false,
    //     error: action.payload,
    //   };
    // case "ORDER_PAY_RESET":
    //   return {};

    /// edit
    case "ORDER_EDIT_REQUEST":
      return {
        loading: true,
      };
    case "ORDER_EDIT_SUCCESS":
      return {
        ...state,
        loading: false,
        order: action.payload.data.data,
      };
    case "ORDER_EDIT_FAIL":
      return {
        loading: false,
        error: action.payload,
      };
    /// order edit address
    case "ADDRESS_EDIT_REQUEST":
      return {
        loading: true,
      };
    case "ADDRESS_EDIT_SUCCESS":
      return {
        ...state,
        loading: false,
        message: "success",
      };
    case "ADDRESS_EDIT_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// export const orderEditReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "ORDER_EDIT_REQUEST":
//       return {
//         loading: true,
//       };
//     case "ORDER_EDIT_SUCCESS":
//       return {
//         loading: false,
//         message: "success",
//       };
//     case "ORDER_EDIT_FAIL":
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export const addressEditReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "ADDRESS_EDIT_REQUEST":
//       return {
//         loading: true,
//       };
//     case "ADDRESS_EDIT_SUCCESS":
//       return {
//         loading: false,
//         message: "success",
//       };
//     case "ADDRESS_EDIT_FAIL":
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export const orderPaymentReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "ORDER_PAY_REQUEST":
//       return {
//         loading: true,
//       };
//     case "ORDER_PAY_SUCCESS":
//       return {
//         loading: false,
//         success: true,
//       };
//     case "ORDER_PAY_FAIL":
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case "ORDER_PAY_REST":
//       return {};

//     default:
//       return state;
//   }
// };
