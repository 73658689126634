import React from "react";
export default function VideoSplash() {
  const mobileSplash = [
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/splashStill1.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/splashStill2.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/splashStill3.png",
    "https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/splashStill4.png",
  ];
  return (
    <div className="w-screen h-screen relative top-20">
      <h1 className="absolute align-center top-1/2 md:left-2.5 text-5xl lg:text-8xl text-pink-500 drop-shadow-xl tracking-widest">
        RANDOM MAN <br />
        EDITIONS
      </h1>
      {window.innerWidth < 400 ? (
        <img
          src={mobileSplash[parseInt(Math.random() * 4, 10)]}
          alt="splash still from video shown on larger screens"
        />
      ) : (
        <video
          muted
          autoPlay
          loop
          id="videoSplash"
          src="https://randommanshopbucketdemo.s3.us-west-1.amazonaws.com/splash.mov"
          type="video/quicktime"
        ></video>
      )}
    </div>
  );
}
