export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_PASSWORD_REQUEST":
      return { loading: true };
    case "UPDATE_PASSWORD_SUCCESS":
      return { loading: false, password: action.payload };
    case "UPDATE_PASSWORD_FAILED":
      return { loading: false, password_error: action.payload };
    case "CREATE_USER_REQUEST":
      return { loading: true };
    case "CREATE_USER_SUCCESS":
      return { loading: false, user: action.payload };
    case "CREATE_USER_FAILED":
      return { loading: false, user_error: action.payload };
    default:
      return state;
  }
};
