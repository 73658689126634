import axios from "axios";
import { singlePublication } from "./publicationActions";
export const addPubMedia = (data, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ADD_PUBLICATION_MEDIA_REQUEST" });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    const pubMedia = await axios.post(
      "/api/productmedia", /// THIS MUST BE CHANGED TO GO BACK
      {
        product: id,
        img: data["product_media_img"],
        video: data["product_media_vid"],
      },
      config
    );
    await dispatch({
      type: "ADD_PUBLICATION_MEDIA_SUCCESS",
      payload: pubMedia,
    });
    await dispatch(singlePublication(id));
  } catch (err) {
    dispatch({
      type: "ADD_PUBLICATION_MEDIA_FAIL",
      payload: err,
    });
  }
};

export const deletePublicationMedia = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "DELETE_PUBLICATION_MEDIA_REQUEST",
    });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    const deletedMedia = await axios.delete(`/api/productmedia/${id}`, config);
    dispatch({
      type: "DELETE_PUBLICATION_MEDIA_SUCCESS",
      payload: deletedMedia,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_PUBLICATION_MEDIA_FAIL",
      payload: err,
    });
  }
};
