import axios from "axios";

export const newOrder = (order) => async (dispatch) => {
  try {
    dispatch({
      type: "ORDER_CREATE_REQUEST",
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const data = await axios.post("/api/createorder", order, config);

    dispatch({
      type: "ORDER_CREATE_SUCCESS",
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: "ORDER_CREATE_FAIL",
      payload: err,
    });
  }
};

export const orderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "ORDER_DETAILS_REQUEST",
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    const data = await axios.get(`/api/orders/${id}`, config);

    dispatch({
      type: "ORDER_DETAILS_SUCCESS",
      payload: data.data.data,
    });
  } catch (err) {
    dispatch({
      type: "ORDER_DETAILS_FAIL",
      payload: err,
    });
  }
};

export const orderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "ORDER_LIST_REQUEST",
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    const data = await axios.get(`/api/orders`, config);
    dispatch({
      type: "ORDER_LIST_SUCCESS",
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: "ORDER_LIST_FAIL",
      payload: err,
    });
  }
};

export const orderEdit = (id, changes) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "ORDER_EDIT_REQUEST",
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };
    const data = await axios.put(`/api/orders/${id}`, changes, config);
    dispatch({
      type: "ORDER_EDIT_SUCCESS",
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: "ORDER_EDIT_FAIL",
      payload: err,
    });
  }
};

export const addressEdit = (id, changes) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "ADDRESS_EDIT_REQUEST",
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getState().userLogin.loginInfo.access}`,
      },
    };

    const data = await axios.put(
      `/api/shipping-address/edit/${id}`,
      changes,
      config
    );
    dispatch({
      type: "ADDRESS_EDIT_SUCCESS",
      payload: data.data.data,
    });
  } catch (err) {
    dispatch({
      type: "ADDRESS_EDIT_FAIL",
      payload: err,
    });
  }
};
