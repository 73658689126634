import React from "react";
import axios from "axios";

export default function Upload({ newImg, setNewImg, loginInfo, img }) {
  const upload = async (files) => {
    if (files === null) return;

    let data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("image", files[i]);
    }

    console.log(data);
    const response = await axios.post("/api/upload", data, {
      headers: {
        Authorization: `Bearer ${loginInfo.access}`,
      },
    });
    if (img) {
      setNewImg([...img, ...response.data.url]);
    } else {
      setNewImg([...response.data.url]);
    }
  };
  return (
    <>
      <h4> Images </h4>
      <div>
        <form>
          <input
            type="file"
            required
            multiple
            onChange={(e) => upload(e.target.files)}
          />
        </form>
      </div>
    </>
  );
}
