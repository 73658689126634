import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { animated, useSpring } from "react-spring";
import {
  editPub,
  singlePublication,
  deletePublication,
  listPublications,
} from "../../actions/publicationActions";
import { useParams, useNavigate } from "react-router-dom";
import MediaManager from "../../components/adminComponents/MediaManager";
import axios from "axios";

export default function EditPub() {
  /// init : get things from redux, define hooks
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userLogin);
  const singlePub = useSelector((state) => state.singlePublication);

  const { loginInfo } = user;
  const { error, product } = singlePub;
  const { data } = product;

  /// form vars
  const title = useRef();
  const author = useRef();
  const price = useRef();
  const qty = useRef();
  const format = useRef();
  const description = useRef();
  const tag = useRef();
  const mediaTypes = useRef();
  const sortRule = useRef();
  /// auth and get pub data
  useEffect(() => {
    if (!loginInfo) navigate("/"); // if there is no user info in the redux state redirect
    const auth = async () => {
      try {
        const response = await axios.post("/api/token/verify/", {
          token: loginInfo.access,
        });
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    };
    auth(); // if there is user info check the token is valid
    dispatch(singlePublication(id)); // get data
  }, [loginInfo, dispatch, navigate, id]);

  // edit action submission
  const editPublication = async (e) => {
    try {
      // let firstnameSort = sortRule.current.value === "1" ? true : false;
      // let titleSort = sortRule.current.value === "2" ? true : false;

      dispatch(
        editPub(
          {
            title: title.current.value,
            author: author.current.value,
            format: format.current.value,
            media_types: mediaTypes.current.value,
            description: description.current.value,
            quantity: parseInt(qty.current.value),
            price: parseFloat(price.current.value),
            tag: tag.current.value,
            use_firstname: sortRule.current.value === "1" ? true : false,
            use_title: sortRule.current.value === "2" ? true : false,
          },
          id
        )
      );
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(singlePublication(id));
      if (singlePub) navigate(`/product/${id}`);
    }
  };

  const deletePub = async (e) => {
    if (
      window.confirm(
        "This will delete this publication and all of it's related media, continue?"
      ) === true
    ) {
      try {
        await dispatch(deletePublication(id));
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch(listPublications());
        navigate("/");
      }
    }
  };

  return (
    <div className="p-10 pt-20">
      <h1 className="title text-3xl p-10">Edit Publication</h1>
      {data ? (
        <div className="p-5 border-1 border-black">
          <form>
            <div className="my-2">
              <label> Title</label>
              <input
                className="pl-2 ml-3 mr-5 w-1/2 border-1 border-black"
                type="string"
                ref={title}
                defaultValue={data.title}
                required
              />
              <label> Author / Artist</label>
              <input
                className="pl-2 ml-3 mr-5 md:w-1/4 border-1 border-black"
                type="string"
                ref={author}
                defaultValue={data.author}
              />
            </div>
            <div className="mt-3">
              <div className="flex flex-col lg:flex-row mt-5">
                <div className="flex flex-col w-full">
                  <label className="text-center">Product Description</label>
                  <textarea
                    className="p-10 ml-3 mr-5 border-1 border-black"
                    style={{ whiteSpace: "pre-wrap" }}
                    ref={description}
                    defaultValue={data.description}
                    required
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="text-center">Format</label>
                  <textarea
                    className="p-10 ml-3 mr-5 border-1 border-black"
                    style={{ whiteSpace: "pre-wrap" }}
                    ref={format}
                    defaultValue={data.format}
                    required
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className="mt-3 flex flex-col">
                    Media Type Options
                    <small>
                      {" "}
                      * use a comma seperated list to enter media options
                    </small>
                  </label>
                  <input
                    className="pl-2 ml-3 mr-5 w-1/2 border-1 border-black"
                    type="string"
                    defaultValue={data.media_types}
                    ref={mediaTypes}
                  />
                </div>
                <div className="mt-3">
                  <label className="mt-3">Price</label>
                  <input
                    className="pl-2 ml-3 mr-5 sm:w-1/6 md:w-1/8 lg:w-1/12 border-1 border-black"
                    type="float"
                    ref={price}
                    defaultValue={data.price}
                    required
                  />
                  <br />
                  <label className="mt-3">Stock</label>
                  <input
                    className="pl-2 ml-3 mr-5 sm:w-1/6 md:w-1/8 lg:w-1/12 border-1 border-black"
                    type="number"
                    ref={qty}
                    defaultValue={data.quantity}
                  />
                  <label className="mt-5 ">Sorting Tag</label>
                  <select
                    className="p-2 ml-5 bg-white border-1 border-black sm:w-1/6 md:w-1/4 lg:w-1/6"
                    aria-label="tag"
                    required
                    ref={tag}
                    defaultValue={data.tag}
                  >
                    <option value="still">Still</option>
                    <option value="moving">Moving</option>
                  </select>
                  <div>
                    {/* ////////// this is where im adding the dropdown for author sorting rules */}
                    <label className="mt-5">Author Sort Rules</label>
                    <select
                      className="p-2 ml-5 bg-white border-1 border-black sm:w-1/6 md:w-1/4 lg:w-1/6"
                      ref={sortRule}
                      defaultValue={
                        data.use_firstname ? "1" : data.use_title ? "2" : "0"
                      }
                    >
                      <option value="0">Sort by last name</option>
                      <option value="1">Sort by first name</option>
                      <option value="2">Sort by title</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/2 md:w-1/5"
              onClick={(e) => editPublication()}
            >
              {" "}
              Save{" "}
            </button>
            <button
              className="p-2 border-1 border-red-500 text-red-500 my-5 md:ml-5 hover:bg-red-500 hover:text-white w-1/2 md:w-1/5"
              onClick={(e) => deletePub()}
            >
              DELETE
            </button>
          </form>
          <MediaManager
            id={id}
            data={data}
            loginInfo={loginInfo}
            img={data.product_media.filter((itm) => itm.img !== null)}
          />
        </div>
      ) : error ? (
        <h1>Error</h1>
      ) : (
        ""
      )}
    </div>
  );
}
