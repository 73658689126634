import React from "react";
export default function Returns() {
  return (
    <div className="p-10 pt-24 subTitle">
      <div className="mt-5">
        <h1 className="text-4xl">Return Policy</h1>

        <p className="mt-5">
          {" "}
          <strong>All Sales final</strong>
        </p>
        <p className="mt-3">
          In the case of an item arriving damaged, replacements can be provided
          subject to availability.
        </p>
        <p>
          If an item is damaged upon arrival and that product is no longer
          available a refund may be issued.{" "}
        </p>
      </div>
    </div>
  );
}
