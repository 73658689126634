import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singlePublication } from "../../actions/publicationActions";
import {
  addPubMedia,
  deletePublicationMedia,
} from "../../actions/pubMediaActions";
import { GoTrashcan } from "react-icons/go";

import Upload from "../../components/Upload";

export default function MediaManager({ id, loginInfo, setImg, img }) {
  const dispatch = useDispatch();
  const singlePub = useSelector((state) => state.singlePublication);

  const [newImg, setNewImg] = useState([]);
  const [video, setVideo] = useState("");
  const [uploadMode, setUploadmode] = useState(false);

  useEffect(() => {}, [singlePub, img]);

  // CHANGE MODE
  const handleChangeMode = (e) => {
    e.preventDefault();
    setUploadmode(!uploadMode);
  };
  // CREATE MEDIA OBJECT IN DB OR IF NEW PUB, ADD TO LIST OF IMGS
  const createPubMedia = async (e) => {
    e.preventDefault();
    if (!id) {
      if (img) {
        setImg([...img, ...newImg]);
      } else {
        setImg([...newImg]);
      }
      setUploadmode(false);
    } else {
      try {
        dispatch(
          addPubMedia(
            {
              product_media_img: newImg,
              product_media_vid: video,
            },
            id
          )
        );
      } catch (err) {
        console.error(err);
      }
    }
  };
  // DELETE MEDIA
  const deletePubMedia = async (e, imgid) => {
    e.preventDefault();
    if (
      singlePub.product.data.product_media.filter((media) => media.img !== null)
        .length < 2
    ) {
      alert(
        "Publications must have a least 1 img, add another img before making this deletion."
      );
      return;
    }
    try {
      await dispatch(deletePublicationMedia(imgid));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(singlePublication(id));
    }
  };

  return (
    <div>
      {uploadMode ? (
        <div>
          <button
            className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/12 md:w-1/12"
            onClick={(e) => handleChangeMode(e)}
          >
            X
          </button>

          {id ? (
            <p>
              * changes to media are seprate from changes to the publication
              information.
            </p>
          ) : (
            ""
          )}
          <br />
          <Upload setNewImg={setNewImg} loginInfo={loginInfo} img={newImg} />

          <h4 className="mt-3"> Video</h4>
          <form>
            <input
              className="border-1 border-black p-2"
              type="string"
              placeholder="embed link for youtube video"
              onChange={(e) => setVideo(e.target.value)}
            />
          </form>
          <button
            className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/2 md:w-1/5"
            onClick={(e) => createPubMedia(e)}
          >
            Save New Images
          </button>
        </div>
      ) : (
        <>
          <button
            className="p-2 border-1 border-black my-5 hover:bg-black hover:text-white w-1/2 md:w-1/5"
            onClick={(e) => handleChangeMode(e)}
          >
            Upload Media
          </button>
        </>
      )}

      <div className="mb-3 flex flex-col lg:flex-row">
        {singlePub.product.data
          ? singlePub.product.data.product_media.map((img, i) => {
              if (img.img) {
                return (
                  <div className="lg:w-1/3" key={`media${i}indata`}>
                    <div
                      className="p-3 border-1 border-red-500 w-fit text-red-500 hover:bg-red-500 hover:text-black"
                      onClick={(e) => deletePubMedia(e, img.id)}
                    >
                      <GoTrashcan />
                    </div>
                    <img src={img.img} alt={`media ${i}`} />
                  </div>
                );
              } else return "";
            })
          : ""}
        {newImg.map((img, i) => {
          if (img) {
            return (
              <div className="lg:w-1/3" key={`media${i}inNewImgArray`}>
                <div
                  className="p-3 border-1 border-red-500 w-fit text-red-500 hover:bg-red-500 hover:text-black"
                  onClick={(e) => deletePubMedia(e, img.id)}
                >
                  <GoTrashcan />
                </div>
                <img src={img} alt={`media ${i}`} />
              </div>
            );
          } else return "";
        })}
        {singlePub.product.data
          ? singlePub.product.data.product_media.map((img, i) => {
              if (img.video) {
                return (
                  <div className="lg:w-1/3" key={`media${i}VideoIndata`}>
                    <div
                      className="p-3 border-1 border-red-500 w-fit text-red-500 hover:bg-red-500 hover:text-black"
                      onClick={(e) => deletePubMedia(e, img.id)}
                    >
                      <GoTrashcan />
                    </div>
                    <iframe
                      className="mt-5"
                      key={i}
                      width="426"
                      height="240"
                      src={img.video}
                      title="YouTube video player"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              } else return "";
            })
          : ""}
      </div>
    </div>
  );
}
